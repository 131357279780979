@font-face {
  font-family: 'Gilroy Black';
  src: url('/assets/fonts/Gilroy-Black.ttf');
}

@font-face {
  font-family: 'Gilroy BlackItalic';
  src: url('/assets/fonts/Gilroy-BlackItalic.ttf');
}

@font-face {
  font-family: 'Gilroy Bold';
  src: url('/assets/fonts/Gilroy-Bold.ttf');
}

@font-face {
  font-family: 'Gilroy BoldItalic';
  src: url('/assets/fonts/Gilroy-BoldItalic.ttf');
}

@font-face {
  font-family: 'Gilroy ExtraBold';
  src: url('/assets/fonts/Gilroy-ExtraBold.ttf');
}

@font-face {
  font-family: 'Gilroy Light';
  src: url('/assets/fonts/Gilroy-Light.ttf');
}

@font-face {
  font-family: 'Gilroy Medium';
  src: url('/assets/fonts/Gilroy-Medium.ttf');
}

@font-face {
  font-family: 'Gilroy Regular';
  src: url('/assets/fonts/Gilroy-Regular.ttf');
}

@font-face {
  font-family: 'Gilroy SemiBold';
  src: url('/assets/fonts/Gilroy-SemiBold.ttf');
}

:root {
  --ons-font-black: 'Gilroy Black';
  --ons-font-black-italic: 'Gilroy BlackItalic';
  --ons-font-bold: 'Gilroy Bold';
  --ons-font-bold-italic: 'Gilroy BoldItalic';
  --ons-font-extra-bold: 'Gilroy ExtraBold';
  --ons-font-light: 'Gilroy Light';
  --ons-font-medium: 'Gilroy Medium';
  --ons-font-regular: 'Gilroy Regular';
  --ons-font-semibold: 'Gilroy SemiBold';
}
