:root {
  //******************* P A D D I N G S ***************
  --ion-padding: 30px;
  --ion-padding-negative: -30px;
  --ion-margin: 30px;

  //******************* C O L O R S ***************
  --ons-color-primary: rgb(8, 255, 25);
  --ons-color-primary-black: rgb(5, 2, 15);
  --ons-color-black-2: rgb(4, 6, 22);
  --ons-color-light: rgb(255, 255, 255);
  --ons-color-white: rgb(254, 255, 254);
  --ons-color-red: rgb(224, 8, 8);
  --ons-color-light-red: rgb(224, 8, 8, 0.06);
  --ons-color-green: rgb(14, 185, 25);
  --ons-color-transparent: rgba(255, 255, 255, 0);
  --ons-color-grey: rgb(150, 159, 162);
  --ons-color-light-grey: rgb(233, 233, 233);
  --ons-color-lighter-grey: rgb(201, 201, 201);
  --ons-color-orange: rgb(255, 156, 0);

  --ons-color-border: rgb(240, 240, 240);
  --ons-grey-border: rgb(151, 151, 151);

  --ons-color-primary-background: rgb(248, 248, 248);

  //******************* O T H E R S ***************
  --ion-fonts-family: var(--ons-font-regular);
  --ons-button-height: 54px;

  //******************* Action Sheet ***************

  .action-sheet-group {
    --background: var(--ons-color-primary-background);

    .action-sheet-title {
      color: white;
      background-color: var(--ons-color-primary-black);
    }

    button.action-sheet-button {
      color: var(--ons-color-primary-black);
      font-size: 14px !important;
    }
  }
}
