.item-interactive.ion-invalid,
.item-interactive.ion-valid {
  // --highlight-background: var(--ons-color-transparent) !important;
}
.item-has-focus.label-stacked.sc-ion-label-md-h,
.item-has-focus .label-stacked.sc-ion-label-md-h,
.item-has-focus.label-floating.sc-ion-label-md-h,
.item-has-focus .label-floating.sc-ion-label-md-h {
  //color:  var(--ons-color-white);
  color: var(--ons-color-primary-black);
}
ion-item.input-item {
  --background: transparent;
  --border-width: 0 0 1px 0;
  --border-color: var(--ons-color-lighter-grey);
  --border-color: var(--ons-color-grey);
  

  font-family: var(--ons-font-medium);
  font-weight: 500;
  &.textarea-item {
    height: auto;
  }
  &.ion-touched {
    &.ion-invalid {
      --border-color: var(--ons-color-red);
    }
    &.ion-valid {
      --border-color: var(--ons-color-primary);

      .show-password {
        color: var(--ons-color-primary);
      }
    }
  }

  --padding-start: 0;
  // --color: white;
  font-size: 16px;
  height: 84px;

  ion-icon {
    margin-top: 34px;
    margin-right: 0;

    &.checkmark {
      color: var(--ons-color-primary);
    }
  }

  ion-label {
    font-size: 14px;
    opacity: 0.8;
    font-family: var(--ons-font-light);
  }
}

.login-button {
  margin-top: 40px;
  --background: var(--ons-color-primary);
  --background-activated: var(--ons-color-primary);
  --background-focused: var(--ons-color-primary);
  --background-hover: var(--ons-color-primary);
  --color: var(--ons-color-primary-black);
  font-size: 16px;
  font-family: var(--ons-font-bold);
  letter-spacing: -0.19px;
  width: 100%;
  --border-radius: 4px;
  height: 54px;
  text-transform: none;
}

.forgot-pass-text {
  color: white;
  font-size: 14px;
  font-family: var(--ons-font-light);
  margin-top: 12px;
  text-align: right;
  cursor: pointer;
}

.signup-block {
  margin-top: 12px;
  color: white;
  font-size: 14px;
  line-height: 24px;
  text-align: center;

  .new-text {
    font-family: var(--ons-font-regular);
  }

  .signup-link {
    margin-left: 7px;
    color: var(--ons-color-primary);
    font-family: var(--ons-font-bold);
    cursor: pointer;
  }
}

.next-button {
  margin-top: 40px;
  --background: var(--ons-color-primary-black);
  --background-activated: var(--ons-color-primary);
  --background-focused: var(--ons-color-primary);
  --background-hover: var(--ons-color-primary);
  --color: white;
  font-size: 14px;
  font-family: var(--ons-font-bold);
  letter-spacing: -0.19px;
  width: 100%;
  --border-radius: 4px;
  height: 54px;
  text-transform: none;
  margin-left: 0;
  margin-right: 0;
}

.gender-select-container {
  margin-top: 27px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;

  .gender-with-text-block {
    display: flex;
    flex-direction: column;
    width: 45%;
    cursor: pointer;

    .gender-box {
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.14);
      height: 77px;
      background: white;
      border-radius: 4px;
      color: var(--ons-color-primary-black);

      &.selected {
        background: var(--ons-color-primary-black);

        ion-icon {
          color: var(--ons-color-primary);
        }
      }

      ion-icon {
        font-size: 31px;
      }
    }

    .gender-text {
      font-family: var(--ons-font-light);
      font-size: 12px;
      height: 14px;
      margin: 7px 0 0;
      opacity: 0.4;
      color: var(--ons-color-black-2);
    }
  }
}
ion-radio {
  --color: var(--ons-color-primary);
  --color-checked: var(--ons-color-primary);
}
ion-checkbox {
  --background-checked: var(--ons-color-primary);
  --border-color-checked: var(--ons-color-primary);
  --border-color: var(--ons-grey-border);
  --size: 20px;
  --checkmark-width: 2px;
  --border-radius: 50%;
  margin-inline-start: 0;
}

ion-range {
  padding: 0 0 30px;
  --bar-background-active: var(--ons-color-primary);

  &::part(pin) {
    font-size: 15px;
    transform: translate3d(0px, 0px, 0px) scale(1);
    top: 45px;
    position: absolute;
    left: 0;
    width: 100%;
  }
}
.range-pins {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;

  .range-pin {
    white-space: nowrap;
  }
}

.add-button-wrapper {
  &.grey {
    background: var(--ons-color-border);
  }
  .ons-add-button {
    --color: var(--ons-color-primary-black);
    text-align: left;
    font-size: 16px !important;
    font-weight: 500;
    font-family: var(--ons-font-medium) !important;
    padding: 14px 30px;
    display: block;
    margin: 0;
    line-height: 2;
    cursor: pointer;
    ion-icon {
      margin-right: 20px;
      font-size: 22px !important;
      position: relative;
      top: 6px;
    }
  }
  &.icon-last {
    .ons-add-button {
      ion-icon {
        margin-left: 20px;
      }
    }
  }
}

quill-editor {
  width: 100%;
  padding-top: 10px;
  -webkit-user-select: text;
}

.input-block {
  display: flex;
  justify-content: center;
  align-items: center;

  ion-input {
    text-align: center;
    max-width: 100px;
    border-bottom: 1px solid var(--ons-color-black-2);
  }
}

app-slider {
  width: 100%;
}

ion-picker {
  .picker-button,
  .picker-opt.picker-opt-selected {
    color: var(--ons-color-primary) !important;
  }
}

.desktop-container {
  max-width: 1000px;
  margin: 0 auto;
}
